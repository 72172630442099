var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCard",
    { staticClass: "shadow-none", attrs: { color: "second-card-bg" } },
    [
      _c("CCardHeader", [_vm._v(" Инвайт-коды ")]),
      _c(
        "CCardBody",
        [
          _c("CDataTable", {
            staticClass: "h-100 overflow-auto",
            attrs: {
              items: _vm.items,
              fields: _vm.fields,
              loading: _vm.loading,
              noItemsView: {
                noResults: _vm.$t("general.noData"),
                noItems: _vm.$t("general.noData"),
              },
              hover: "",
              pagination: "",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }