<template>
  <CCard
    color="second-card-bg"
    class="shadow-none"
  >
    <CCardHeader>
      Инвайт-коды
    </CCardHeader>
    <CCardBody>
      <CDataTable
        class="h-100 overflow-auto"
        :items="items"
        :fields="fields"
        :loading="loading"
        :noItemsView="{ noResults: $t('general.noData'), noItems: $t('general.noData') }"
        hover
        pagination
      />
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  name: "InviteTable",
  props: {
    items: {
      type: Array,
      default: () => ([])
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      fields: [
        { key: 'code', label: 'Код' },
        { key: 'validity', label: 'Срок действия' }
      ]
    }
  }
}
</script>

<style scoped>

</style>
