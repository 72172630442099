<template>
  <CCard
    color="second-card-bg"
    class="shadow-none"
  >
    <CCardHeader>
      Загрузка списка кодов
    </CCardHeader>
    <CCardBody>
      <CInputFile
        class="mb-4"
        horizontal
      />

      <label>На какой срок загружаете коды?</label>
      <div>
        <CButton
          v-for="(item, i) in dateList"
          :key="i"
          :color="item.key === dateActive ? 'primary' : 'light'"
          shape="pill"
          class="mr-2 mb-2 px-4 font-weight-bold shadow-none"
          @click="dateActive = item.key"
          size="sm"
        >
          {{item.label.toUpperCase()}}
        </CButton>
      </div>
<!--      <CInput
        placeholder="Введите E-mail"
      >
        <template #prepend-content>
          <CIcon
            class="text-dark"
            name="mail"
          />
        </template>
      </CInput>
      <CInput
        placeholder="Количество дней доступа"
      >
        <template #prepend-content>
          <CIcon
            class="text-dark"
            name="cil-calendar"
          />
        </template>
      </CInput>-->

      <CButton
        class="mt-3 shadow-none"
        color="primary"
      >
        Загрузить список кодов
        <CIcon
          name="cil-cloud-upload"
        />
      </CButton>
    </CCardBody>
  </CCard>
</template>
<script>
export default {
  name: 'LoadFile',
  data () {
    return {
      dateActive: null,
      dateList: [{
        key: 'month3',
        label: '3 Месяца'
      }, {
        key: 'full',
        label: 'Вечный'
      }]
    }
  }
}
</script>
