<template>
  <div class="h-100 d-flex flex-column">
    <h1 class="mb-3 title">Инвайт-коды (Тренды)</h1>

    <CRow class="flex-grow-1 overflow-hidden">
      <CCol col="12" lg="4">
        <load-file />
      </CCol>
      <CCol col="12" lg="8" class="h-100 overflow-hidden">
        <invite-table
          class="h-100 overflow-hidden"
          :items="[{
            code: 'HJKDD238RQQF1231',
            validity: '3 месяца'
          }, {
            code: 'HJKDD238RQQF1231',
            validity: 'Вечный'
          }, {
            code: 'HJKDD238RQQF1231',
            validity: '2 месяца'
          }, {
            code: 'HJKDD238RQQF1231',
            validity: '3 месяца'
          }, {
            code: 'HJKDD238RQQF1231',
            validity: 'Вечный'
          }, {
            code: 'HJKDD238RQQF1231',
            validity: '2 месяца'
          }, {
            code: 'HJKDD238RQQF1231',
            validity: '3 месяца'
          }, {
            code: 'HJKDD238RQQF1231',
            validity: 'Вечный'
          }, {
            code: 'HJKDD238RQQF1231',
            validity: '2 месяца'
          }]"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import InviteTable from "@/components/invite/InviteTable";
import LoadFile from "@/components/invite/LoadFile";
export default {
  name: "InviteCode",
  components: {
    InviteTable,
    LoadFile
  },
  data() {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
