var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCard",
    { staticClass: "shadow-none", attrs: { color: "second-card-bg" } },
    [
      _c("CCardHeader", [_vm._v(" Загрузка списка кодов ")]),
      _c(
        "CCardBody",
        [
          _c("CInputFile", { staticClass: "mb-4", attrs: { horizontal: "" } }),
          _c("label", [_vm._v("На какой срок загружаете коды?")]),
          _c(
            "div",
            _vm._l(_vm.dateList, function (item, i) {
              return _c(
                "CButton",
                {
                  key: i,
                  staticClass: "mr-2 mb-2 px-4 font-weight-bold shadow-none",
                  attrs: {
                    color: item.key === _vm.dateActive ? "primary" : "light",
                    shape: "pill",
                    size: "sm",
                  },
                  on: {
                    click: function ($event) {
                      _vm.dateActive = item.key
                    },
                  },
                },
                [_vm._v(" " + _vm._s(item.label.toUpperCase()) + " ")]
              )
            }),
            1
          ),
          _c(
            "CButton",
            { staticClass: "mt-3 shadow-none", attrs: { color: "primary" } },
            [
              _vm._v(" Загрузить список кодов "),
              _c("CIcon", { attrs: { name: "cil-cloud-upload" } }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }