var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-100 d-flex flex-column" },
    [
      _c("h1", { staticClass: "mb-3 title" }, [_vm._v("Инвайт-коды (Тренды)")]),
      _c(
        "CRow",
        { staticClass: "flex-grow-1 overflow-hidden" },
        [
          _c("CCol", { attrs: { col: "12", lg: "4" } }, [_c("load-file")], 1),
          _c(
            "CCol",
            {
              staticClass: "h-100 overflow-hidden",
              attrs: { col: "12", lg: "8" },
            },
            [
              _c("invite-table", {
                staticClass: "h-100 overflow-hidden",
                attrs: {
                  items: [
                    {
                      code: "HJKDD238RQQF1231",
                      validity: "3 месяца",
                    },
                    {
                      code: "HJKDD238RQQF1231",
                      validity: "Вечный",
                    },
                    {
                      code: "HJKDD238RQQF1231",
                      validity: "2 месяца",
                    },
                    {
                      code: "HJKDD238RQQF1231",
                      validity: "3 месяца",
                    },
                    {
                      code: "HJKDD238RQQF1231",
                      validity: "Вечный",
                    },
                    {
                      code: "HJKDD238RQQF1231",
                      validity: "2 месяца",
                    },
                    {
                      code: "HJKDD238RQQF1231",
                      validity: "3 месяца",
                    },
                    {
                      code: "HJKDD238RQQF1231",
                      validity: "Вечный",
                    },
                    {
                      code: "HJKDD238RQQF1231",
                      validity: "2 месяца",
                    },
                  ],
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }